import { Controller } from "@hotwired/stimulus"
import fetchWithCsrf from '../../src/shared/helpers/fetch_json_with_csrf'

// Connects to data-controller="post--contact-block"
export default class extends Controller {
  static targets = ['phones', 'phoneLink', 'whatsappLink']
  static values = { placementNumber: String }
  static observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };
  static phonesVisibilityTimeout = 2000;

  connect() {
    if (this.phonesTarget && this.isVisible(this.phonesTarget)) {
      this.observer = this.startPhonesObserver(this.phonesTarget)
    }

    if (this.hasPhoneLinkTarget) {
      this.phoneLinkTarget.addEventListener('click', () => this.saveContactView('phone_link'))
    }

    if (this.hasWhatsappLinkTarget) {
      this.whatsappLinkTarget.addEventListener('click', () => this.saveContactView('whatsapp_link'))
    }
  }

  isVisible(element) {
    return element && element.offsetWidth > 0 && element.offsetHeight > 0;
  }

  startPhonesObserver(phonesElement) {
    const observer = new IntersectionObserver(
      (entries) => { this.phonesBecameVisible(entries) },
      this.constructor.observerOptions);
    observer.observe(phonesElement);

    return observer;
  }

  phonesBecameVisible(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && document.visibilityState === 'visible') {
        this.phonesVisibilityTimeout = this.startPhonesVisibilityTimeout();
      } else {
        clearTimeout(this.phonesVisibilityTimeout);
      }
    })
  }

  startPhonesVisibilityTimeout() {
    return setTimeout(
      () => { this.saveContactView('scrolled') },
      this.constructor.phonesVisibilityTimeout
    )
  }

  reveal(e) {
    e.preventDefault()
    this.element.classList.remove('-hidden')

    this.saveContactView('show_contact_button')
  }

  saveContactView(source) {
    if (this.isAleadyViewed(source)) {
      return
    }

    const data = {
      contact_view: {
        placement_number: this.placementNumberValue,
        url: location.href,
        source: source
      }
    }

    fetchWithCsrf('/contact_views', { method: 'POST', body: JSON.stringify(data) })
      .then(response => {
        console.log('Contact view saved', this.placementNumberValue, source)
        this.rememberView(source)
      })
  }

  rememberView(source) {
    this.savedViews ||= [];
    this.savedViews.push(source);
  }

  isAleadyViewed(source) {
    if (!this.savedViews) {
      return false;
    }
    return this.savedViews.includes(source);
  }
}
